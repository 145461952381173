
import { defineComponent } from "vue";
import SettlementEntityDetail from "@/components/page/kyc/settlement-entity/legal-entity/Detail.vue";

export default defineComponent({
  name: "settlement-entity-legal-entity-detail",
  components: {
    SettlementEntityDetail,
  },
  setup() {
    return {};
  },
});
